<template>
  <div class="pt-16">
  <div class="p-4 sm:ml-64">
    <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-white dark:bg-gray-800">

      <!-- Title -->

      <p class="text-3xl/loose font-bold text-black dark:text-white">Self Intro</p>


      <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">

      </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'Home',
  data() {
      return {
      }
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  }
}
</script>