import { createRouter, createWebHistory } from 'vue-router'
import Home from '/src/views/Home.vue'
import Project from '/src/views/Project.vue'

const routes = [
    {
    path: '/',
    redirect: '/Home'
    },
    {
        path:'/Home',
        name:'Home',
        component:Home
    },
    {
        path:'/Project',
        name:'Project',
        component:Project
    },
]   
const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router